import React from 'react'
import ArrivalImg1 from '../../assets/images/infoArrivalImg1.png';

export default function ArrivalContents() {
  return (
    <div className={'w-full text-center py-[50px]'}>
      <div className={'pretendard-extrabold text-black text-[28px]'}>5분 전 도착</div>
      <div className={'mt-[20px] text-center px-[24px]'}>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          환복, 바이크 피팅, 준비 운동을 위해 예약 시간 5분 전에 B52 스테이션에 도착해주세요. <br />
          실제 수업은 예약 시간 5분 후에 시작됩니다.
        </div>
        <div className={'mt-[10px] pretendard-medium text-[#353535] text-[20px]'}>
          (19:00-19:20 수업의 경우, 19:05에 운동 시작)
        </div>
      </div>
      <div className={'mt-[50px] pretendard-extrabold text-black text-[28px]'}>QR코드 출입</div>
      <div className={'mt-[20px] text-center px-[24px]'}>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          예약 시간 전후 15분에 매장 출입을 위한 QR코드가 활성화 됩니다.
        </div>
        <div className={'mt-[10px] pretendard-medium text-[#353535] text-[20px]'}>
          (19:00-19:20 수업의 경우,<br />
          18:45-19:35에 QR코드 활성화)
        </div>
        <div className={'mt-[10px] pretendard-medium text-[#353535] text-[20px]'}>
          모바일앱 하단에 위치한  ‘로그인‘ 버튼을 누르고 QR 출입증을 띄운 후, 출입문 센서에 QR코드를 보여주세요.
        </div>
        <img src={ArrivalImg1} className={'mt-[24px] m-auto w-[281px] h-[281px]'} />
      </div>
      <div className={'mt-[50px] pretendard-extrabold text-black text-[28px]'}>탈의 및 보관</div>
      <div className={'mt-[20px] text-center px-[24px] mb-[50px]'}>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          아쉽게도 B52 스테이션에는 별도 탈의실 및 샤워실이 존재하지 않습니다. <br />
          건물 지하 1층에 위치한 깔끔한 화장실을 이용하여 환복 가능합니다. 매장에 비치된 검은색 바구니에 개인짐은 보관 가능합니다.
        </div>
      </div>
    </div>
  )
}
