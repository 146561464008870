import { Link } from 'react-router-dom';
import ExpImg1 from '../../assets/images/infoExpImg1.png';
import ExpImg2 from '../../assets/images/infoExpImg2.png';
import ExpImg3 from '../../assets/images/infoExpImg3.png';
import ExpImg4 from '../../assets/images/infoExpImg4.png';
import MoreButton from '../../components/small_parts/MoreButton';

export default function ExpContents() {
  return (
    <div className={'w-full text-center pt-[50px] pb-[100px]'}>
      <div className={'pretendard-extrabold text-black text-[28px]'}>폭격 시작</div>
      <div className={'mt-[20px] text-center px-[24px]'}>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          수업 시작 30초 카운트다운이 시작되면 바이크에 앉아 서서히 페달링을 시작해주세요. <br />
          수업이 시작되면 바이크 태블릿이 계기판 화면으로 전환됩니다. 콘텐츠에 맞춰 바이크의 저항과 목표 속도가 실시간으로 변화합니다. <br />
          목표 속도에 맞춰 힘차게 페달링을 해주세요.
        </div>
      </div>
      <img src={ExpImg1} className={'mt-[20px]'} />
      <div className={'mt-[20px] text-center px-[24px]'}>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          만약 저항이 너무 가볍거나 무거우면, 핸들 가운데에 위치한 다이얼을 돌려 저항을 조절해주세요.
        </div>
      </div>
      <img src={ExpImg2} className={'mt-[20px]'} />

      <div className={'mt-[60px] pretendard-extrabold text-black text-[28px]'}>올바른 자세</div>
      <div className={'mt-[20px] text-center px-[24px]'}>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          RECOVERY/CLIMBING/RACING 등 운동 구간에 맞춰 핸들 그립을 바꾸고 자세를 변경해주세요.올바른 자세를 통해 운동 효과를 높일 수 있습니다.
        </div>
      </div>
      <img src={ExpImg3} className={'mt-[20px]'} />
      <Link to="/notice/4">
        <MoreButton text={'핸들바 파지법 보기'} />
      </Link>
      <div className={'mt-[30px] text-center px-[24px]'}>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          엉덩이를 안장에 고정시키고 상체의 흔들림 없이 페달을 회전시키는게 핵심입니다. 라이딩 자세가 익숙치 않은 분들은 ‘레슨’ 콘텐츠 이용을 권장드립니다.
        </div>
      </div>
      <img src={ExpImg4} className={'mt-[20px]'} />
      <Link to="/notice/5">
        <MoreButton text={'라이딩 포지션 보기'} />
      </Link>

      <div className={'mt-[60px] pretendard-extrabold text-black text-[28px]'}>이슈 발생시 대응방안</div>
      <div className={'mt-[20px] text-center px-[24px]'}>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          B52는 실시간 스트리밍 기술을 기반으로 서비스가 이루어지다보니, 때론 인터넷망 이슈로 서비스의 장애가 발생할 수도 있습니다. <br />
          이슈 발생시 당황하지 마시고, 태블릿의 앱을 종료 후 재실행 해주세요.
        </div>
      </div>
      <a href="http://pf.kakao.com/_xhxkqrG/chat" target="_blank">
        <MoreButton text={'온라인 고객센터'} />
      </a>
    </div>
  )
}
