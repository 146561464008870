import DownAosImg from '../../assets/images/down-aos@3x.png';
import DownIosImg from '../../assets/images/down-ios@3x.png';

export default function StoreLink() {
  return (
    <div className={'mt-[27px] mb-[40px]'}>
      <a href={'https://play.google.com/store/apps/details?id=fit.paprica.mobile'} target='_blank'>
        <img src={DownAosImg} className={`w-[200px] h-[48px] m-auto`} />
      </a>

      <a href={'https://apps.apple.com/us/app/popprika/id1575480079'} target="_blank">
        <img src={DownIosImg} className={`w-[200px] h-[48px] m-auto mt-[16px]`} />
      </a>
    </div>
  )
}
