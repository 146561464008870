import React, { useState } from 'react';
import AboutTopImg from '../assets/images/aboutTopImg.png';
import IconMoreImg from '../assets/images/ico-more@3x.png';
import AboutImg1 from '../assets/images/aboutImg1_3x.png';
import AboutImg2_1 from '../assets/images/aboutImg2-1_2xm.png';
import AboutImg2_2 from '../assets/images/aboutImg2-2_2xm.png';
import AboutImg2_3 from '../assets/images/aboutImg2-3_2xm.png';
import AboutImg2_4 from '../assets/images/aboutImg2-4_2xm.png';
import ContentInfo from '../components/small_parts/ContentInfo';
import AboutModalImg1 from '../assets/images/aboutModalImg1.png';
import XWhiteImg from '../assets/images/x_white.png';
import Modal from 'react-modal';
import AboutModal from '../components/AboutModal';

const About: React.FC = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalNumber, setModalNumber] = useState(1);

  function openModal(modalNumber: number) {
    setIsOpen(true);
    setModalNumber(modalNumber);
  }
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div style={styles.container}>
      {/* section #1 */}
      <div className={'relative'}>
        <img src={AboutTopImg} className={'w-full'} alt="B52 Top" />
        <div className={'absolute bottom-0 text-white px-[20px] pb-[44px]'}>
          <span className={'pretendard-bold text-[20px]'}>It's a club, not a gym</span>
          <div className={`mt-[7px]`}>
            <div style={styles.textStroke} className={'pretendard-extrabold'}>RIDE THE</div>
            <div style={styles.headTextBig} className={'pretendard-extrabold'}>MUSIC</div>
            <div style={styles.textStroke} className={'pretendard-extrabold'}>PLAY THE</div>
            <div style={styles.headTextBig} className={'pretendard-extrabold'}>BIKE</div>
          </div>
          <div
            className={`mt-[27px] w-[48px] h-[48px] flex justify-center items-center bg-[#ae02ef] rounded-full justify-self-start cursor-pointer`}
            onClick={() => {
              setModalNumber(1);
              openModal(1);
            }}
          >
            <img src={IconMoreImg} className={`w-[19px] h-[18px]`} />
          </div>
        </div>
      </div>

      {/* section #2 */}
      <div className={'bg-[#fff] text-center py-[60px]'}>
        <div className={'pretendard-bold text-black text-[50px] leading-[56px]'}>
          결과를 보장해주는
        </div>
        <div className={'pretendard-bold text-black text-[50px] leading-[56px]'}>
          최첨단 바이크
        </div>

        <div className={'mt-[20px] mb-[60px]'}>
          <div className={'pretendard-medium text-black text-[20px]'}>
            실시간 자동 제어되는 바이크를 통해
          </div>
          <div className={'pretendard-medium text-black text-[20px]'}>
            운동효과를 극대화시킵니다.
          </div>
          <div className={'pretendard-medium text-black text-[20px]'}>
            빠르게 질주하는 RACING 구간을 통해
          </div>
          <div className={'pretendard-medium text-black text-[20px]'}>
            칼로리 소모와 심폐지구력 향상을,
          </div>
          <div className={'pretendard-medium text-black text-[20px]'}>
            오르막을 오르는 CLIMBING 구간을 통해
          </div>
          <div className={'pretendard-medium text-black text-[20px]'}>
            하체/코어 근력을 강화할 수 있습니다.
          </div>
        </div>

        <div className="relative">
          <img src={AboutImg1} className={'w-full'} />
          <div className={'absolute top-[63%] left-[52%]'}>
            <div
              className={`w-[48px] h-[48px] flex justify-center items-center bg-[#ae02ef] opacity-80 rounded-full justify-self-start cursor-pointer`}
              onClick={() => {
                setModalNumber(2);
                openModal(2);
              }}
            >
              <img src={IconMoreImg} className={`w-[19px] h-[18px]`} />
            </div>
          </div>

          <div className={'absolute top-[35%] left-[45%]'}>
            <div
              className={`w-[48px] h-[48px] flex justify-center items-center bg-[#ae02ef] opacity-80 rounded-full justify-self-start cursor-pointer`}
              onClick={() => {
                setModalNumber(3);
                openModal(3);
              }}
            >
              <img src={IconMoreImg} className={`w-[19px] h-[18px]`} />
            </div>
          </div>

          <div className={'absolute top-[50%] left-[60%]'}>
            <div
              className={`w-[48px] h-[48px] flex justify-center items-center bg-[#ae02ef] opacity-80 rounded-full justify-self-start cursor-pointer`}
              onClick={() => {
                setModalNumber(4);
                openModal(4);
              }}
            >
              <img src={IconMoreImg} className={`w-[19px] h-[18px]`} />
            </div>
          </div>

          <div className={'absolute top-[52%] left-[85%]'}>
            <div
              className={`w-[48px] h-[48px] flex justify-center items-center bg-[#ae02ef] opacity-80 rounded-full justify-self-start cursor-pointer`}
              onClick={() => {
                setModalNumber(5);
                openModal(5);
              }}
            >
              <img src={IconMoreImg} className={`w-[19px] h-[18px]`} />
            </div>
          </div>

          <div className={'absolute top-[75%] left-[63%]'}>
            <div
              className={`w-[48px] h-[48px] flex justify-center items-center bg-[#ae02ef] opacity-80 rounded-full justify-self-start cursor-pointer`}
              onClick={() => {
                setModalNumber(6);
                openModal(6);
              }}
            >
              <img src={IconMoreImg} className={`w-[19px] h-[18px]`} />
            </div>
          </div>
        </div>

        <div className={'mt-[30px]'}>
          <div className={'pretendard-medium text-black text-[20px]'}>
            운동을 마친 후에도 부족한 산소량을 상쇄하기
          </div>
          <div className={'pretendard-medium text-black text-[20px]'}>
            위해 칼로리 연소, 지방산화를 동반한
          </div>
          <div className={'pretendard-medium text-black text-[20px]'}>
            에프터번(Afterburn Effect) 효과를 얻습니다.
          </div>
        </div>
      </div>

      {/* section #3 */}
      <div className={'mt-[14px] bg-[#fff] text-center pt-[60px] pb-[100px]'}>
        <div className={'pretendard-bold text-black text-[50px] leading-[56px]'}>
          10분 순삭
        </div>
        <div className={'pretendard-bold text-black text-[50px] leading-[56px]'}>
          B52 콘텐츠
        </div>

        <ContentInfo imgSrc={AboutImg2_1} title={'레슨'} desc={'짜임새 있는 커리큘럼'} />
        <ContentInfo imgSrc={AboutImg2_2} title={'리얼'} desc={'광활한 자연과 함께 힐링'} />
        <ContentInfo imgSrc={AboutImg2_3} title={'뮤비'} desc={'나만의 미니 콘서트'} />
        <ContentInfo imgSrc={AboutImg2_4} title={'그래픽'} desc={'신나는 EDM과 화려한 그래픽'} />
      </div>

      <AboutModal isOpen={modalIsOpen} closeModal={closeModal} modalNumber={modalNumber} />

    </div>
  );
};

const styles = {
  container: {
    // padding: '20px',
  },
  headTextBig: {
    fontSize: '68px',
    lineHeight: '68px',
    color: '#fff',
  },
  headTextBigBorder: {
    fontSize: '68px',
    lineHeight: '68px',
    border: '1px solid #fff',
  },
  headTextSmall: {
    fontFamily: 'pretendard-medium',
    fontSize: '20px',
    color: '#fff',
  },
  textStroke: {
    WebkitTextStroke: '1.4px white',
    fontSize: '68px',
    lineHeight: '68px',
    color: 'rgba(255, 255, 255, 0)',
    // textShadow: '-1px 0px yellow, 0px 1px yellow 1px 0px yellow, 0px -1px yellow',
  }
};

export default About;
