import React from 'react'
import useSWR from "swr";
import { API_HOST } from '../constants/API_HOST';
import { Link } from 'react-router-dom';
import Loading from '../components/Loading';

export type BannerType = {
  studio_banner_id: number;
  img_url: string;
  title: string;
  description: string;
}

export default function Notice() {
  const fetcher = (url: string) => fetch(API_HOST + url).then((res) => res.json());
  const { data, isLoading, error } = useSWR<BannerType[]>("/api/v1/commons/banners/", fetcher);

  console.log("data: ", data);
  console.log("error: ", error);

  return (
    <div className={'bg-white'}>
      <div className={'py-[30px]'}>
        <div className={'w-full text-center'}>
          <div className={'pretendard-bold text-black text-[50px]'}>
            What's New
          </div>
        </div>
      </div>

      {/* 데이터 From Server */}
      <div className={'pb-[100px]'}>
        {data?.map((v, i) => (
          <Link to={`/notice/${v.studio_banner_id}`}>
            <div className={'relative mb-[90px]'}>
              {/* <div className={'w-full h-[300px]'} style={{ backgroundImage: `url(${v.img_url})`, backgroundSize: 'cover', backgroundPosition: '80%' }}>
            </div> */}
              <img src={v.img_url} className={'w-full bg-black'} />
              <div className={'absolute -bottom-[63px] px-[24px] w-full'}>
                <div className={'-mb-[20px] flex justify-center items-center'}>
                  <div className={'bg-[#ae02ef] h-[40px] flex justify-center items-center px-[18px] pretendard-bold text-white text-[18px]'}>
                    {v.title}
                  </div>
                </div>

                <div className={'bg-white w-full h-[126px] px-[23px] py-[30px] text-center'}>
                  <p className={'pretendard-bold text-black text-[22px] text-ellipse line-clamp-2'}>
                    {v.description}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>

      {isLoading && <Loading />}

    </div>
  )
}
