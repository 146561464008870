import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import WelcomeContents from '../components/info_parts/WelcomeContents';
import MenuLink from '../components/info_parts/MenuLink';
import { Link } from 'react-router-dom'
import InfoHeader from '../components/info_parts/InfoHeader';
import ReadyContents from '../components/info_parts/ReadyContents';
import ArrivalContents from '../components/info_parts/ArrivalContents';
import SetupContents from '../components/info_parts/SetupContents';
import ExpContents from '../components/info_parts/ExpContents';
import TrialContents from '../components/info_parts/TrialContents';
import PaymentContents from '../components/info_parts/PaymentContents';



export default function Info() {
  const params = useParams();
  const [menuOpen, setMenuOpen] = useState(false);
  const customStyles = {
    content: {
      bottom: 'auto',
      width: '100%',
      maxWidth: '430px',
      margin: '0 auto',
      height: '100%',
      border: 0,
      borderRadius: 0,
      padding: 0,
      inset: '60px 0 0 0',
    },
  };
  const [subMenu, setSubMenu] = useState<string | undefined>();

  useEffect(() => {
    console.log("params.subMenu: ", params.subMenu);
    setSubMenu(params.subMenu);
  }, [params.subMenu])

  return (
    <div className={'bg-white relative'}>
      {/* Menu 영역 */}
      <MenuLink menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      {/* 헤더 영역 */}
      <InfoHeader subMenu={subMenu} />

      {/* 컨텐츠 영역 */}
      {!subMenu && <WelcomeContents />}
      {subMenu === 'trial' && <TrialContents />}
      {subMenu === 'ready' && <ReadyContents />}
      {subMenu === 'arrival' && <ArrivalContents />}
      {subMenu === 'setup' && <SetupContents />}
      {subMenu === 'exp' && <ExpContents />}
      {subMenu === 'payment' && <PaymentContents />}

      {menuOpen && (
        <div className={'absolute top-[50px] w-full flex justify-center'}>
          <div style={styles.menuPopup} className={'absolute text-center'} onClick={() => setMenuOpen(!menuOpen)}>
            <Link to='/info'>
              <div className="pretendard-bold text-black text-[22px] leadig-[20px]">Welcome</div>
            </Link>
            <Link to='/info/trial'>
              <div className="pretendard-regular text-black text-[20px] leading-[21px] mt-[10px]">체험</div>
            </Link>
            <Link to='/info/ready'>
              <div className="pretendard-regular text-black text-[20px] leading-[21px] mt-[10px]">준비</div>
            </Link>
            <Link to='/info/arrival'>
              <div className="pretendard-regular text-black text-[20px] leading-[21px] mt-[10px]">매장 도착</div>
            </Link>
            <Link to='/info/setup'>
              <div className="pretendard-regular text-black text-[20px] leading-[21px] mt-[10px]">바이크 셋업</div>
            </Link>
            <Link to='/info/exp'>
              <div className="pretendard-regular text-black text-[20px] leading-[21px] mt-[10px]">칼로리 폭격</div>
            </Link>
            <Link to='/info/payment'>
              <div className="pretendard-bold text-black text-[22px] mt-[20px]">결제 및 예약</div>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

const styles = {
  priceBox: {
    width: '100%',
    height: '78px',
    paddingLeft: '24px',
    paddingRight: '24px',
    border: '1px solid #ae02ef',
    borderRadius: '23px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '1px 3px 6px 0 rgba(0, 0, 0, 0.2)',
  },
  menuPopup: {
    width: '216px',
    height: '282px',
    margin: '0 auto',
    padding: '18px',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.3)',
    border: 'solid 1px #e4e4e4',
    backgroundColor: '#fff',
  }
}