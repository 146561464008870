import React from 'react'
import StarImg from '../assets/images/star@3x.png';
import StarDimImg from '../assets/images/star-dim@3x.png';

type Props = {
  score: number;
  contents: string;
  author: string;
}

export default function ReviewItem({ score, contents, author }: Props) {
  return (
    <div style={styles.reviewBox}>
      <div className={`flex flex-row justify-center items-center`}>
        <img src={score > 0 ? StarImg : StarDimImg} alt="star" className={'w-[36px] h-[36px]'} />
        <img src={score > 1 ? StarImg : StarDimImg} alt="star" className={'w-[36px] h-[36px]'} />
        <img src={score > 2 ? StarImg : StarDimImg} alt="star" className={'w-[36px] h-[36px]'} />
        <img src={score > 3 ? StarImg : StarDimImg} alt="star" className={'w-[36px] h-[36px]'} />
        <img src={score > 4 ? StarImg : StarDimImg} alt="star" className={'w-[36px] h-[36px]'} />
      </div>

      <div className={'mt-[18px]'} dangerouslySetInnerHTML={{ __html: contents }}>
      </div>

      <div className={`mt-[16px] text-black pretendard-medium`}>{author}</div>
    </div>
  )
}

const styles = {
  reviewBox: {
    maxWidth: '378px',
    minHeight: '418px',
    margin: '20px 25px 40px 27px',
    padding: '28px 18px 43px 20px',
    borderRadius: '8px',
    boxShadow: '1px 3px 6px 0 rgba(0, 0, 0, 0.2)',
    border: 'solid 1px #e8e8e8',
    backgroundColor: '#fff',
  }
}