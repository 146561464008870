import React from 'react'
import Spinner from '../assets/images/gif/flight-loading.gif';

export default function Loading() {
  return (
    <div style={styles.container} className={'w-full h-full absolute flex justify-center items-center'}>
      <img src={Spinner} alt='Loading...' />
    </div>
  )
}

const styles = {
  container: {
    top: 0,
    left: 0,
    background: '#ffffff',
    zIndex: 99,
  },
}
