import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import About from './pages/About';
import Footer from './components/layouts/Footer';
import Modal from 'react-modal';
import ScrollToTop from './components/ScrollToTop';
import Welcome from './pages/Info';
import B52FullBi from './assets/images/b52FullBi3x.png';
import QrCode from './assets/images/univeralStoreLinkQrCode_resized.png';
import Notice from './pages/Notice';
import NoticeDetail from './pages/NoticeDetail';

Modal.setAppElement('#root');

function App() {
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const customStyles = {
    content: {
      // top: '60px',
      bottom: 'auto',
      width: '100%',
      maxWidth: '430px',
      margin: '0 auto',
      height: '100%',
      border: 0,
      borderRadius: 0,
      padding: 0,
      inset: '60px 0 0 0',
    },
  };

  const body = document.querySelector('body') as HTMLElement;
  useEffect(() => {
    if (modalIsOpen) {
      body.style.overflow = 'hidden';
    } else {
      body.style.removeProperty('overflow');
    }
  }, [modalIsOpen]);

  return (
    <Router>
      <ScrollToTop />
      <div id={'app_wrapper'} style={styles.app}>
        <Header modalIsOpen={modalIsOpen} openModal={openModal} closeModal={closeModal} />
        <main style={styles.main}>
          <Routes>
            <Route path="/" element={<Home modalIsOpen={modalIsOpen} />} />
            <Route path="/about" element={<About />} />
            <Route path="/info" element={<Welcome />} />
            <Route path="/info/:subMenu" element={<Welcome />} />
            <Route path="/notice" element={<Notice />} />
            <Route path="/notice/:id" element={<NoticeDetail />} />
          </Routes>
        </main>
        <Footer />
      </div>
      <section id={'pc_info'} style={styles.pcInfo}>
        <img src={B52FullBi} />
        <img src={QrCode} className={'mt-[20px] w-[120px] h-[120px]'} />
        <div className={'mt-[10px] pretendard-medium text-[#757575] text-base text-center'}>
          QR 찍고<br />
          앱에서 더 많은<br />
          혜택받기
        </div>
      </section>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="bg-white">
          <Link to={'/'} onClick={closeModal}>
            <div style={styles.menuItemBox}>
              <div className={'pretendard-bold text-black text-[26px]'}>Home</div>
            </div>
          </Link>
          <Link to={'/about'} onClick={closeModal}>
            <div style={styles.menuItemBox}>
              <div className={'pretendard-bold text-black text-[26px]'}>What is B52</div>
            </div>
          </Link>
          <Link to={'/info'} onClick={closeModal}>
            <div style={styles.menuItemBox}>
              <div className={'pretendard-bold text-black text-[26px]'}>New Rider</div>
            </div>
          </Link>
          <Link to={'/notice'} onClick={closeModal}>
            <div style={styles.menuItemBox}>
              <div className={'pretendard-bold text-black text-[26px]'}>What's New</div>
            </div>
          </Link>

          <div className={`absolute bottom-28 w-full px-[24px]`}>
            <div className={'flex justify-center bottom-0'}>
              <Link to='/info/trial' onClick={closeModal}>
                <div className={'w-[382px] h-[66px] bg-black rounded-full flex justify-center items-center'}>
                  <div className={'pretendard-bold text-white text-[22px]'}>무료 체험</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </Router>
  );
}

const styles = {
  app: {
    minHeight: '100vh',
  },
  main: {
    flex: 1,
    // padding: '20px',
  },
  pcInfo: {
    // backgroundColor: '#0ab',
    // opacity: 0.5,
  },
  pcTitle: {
    color: '#000',
    fontSize: '5vw',
  },
  menuItemBox: {
    padding: '20px 24px 20px 24px',
    borderBottom: '1px solid #d1d1d1',
  }
};


export default App;
