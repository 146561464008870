import Modal from 'react-modal';
import XWhiteImg from '../assets/images/x_white.png';
import AboutModalImg1 from '../assets/images/aboutModalImg1.png';
import AboutModalImg2 from '../assets/images/aboutModalImg2.png';
import AboutModalImg3 from '../assets/images/aboutModalImg3.png';
import AboutModalImg4 from '../assets/images/aboutModalImg4.png';
import AboutModalImg5 from '../assets/images/aboutModalImg5.png';
import AboutModalImg6 from '../assets/images/aboutModalImg6.png';
import { useEffect } from 'react';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  modalNumber: number;
}

export default function AboutModal({
  isOpen,
  closeModal,
  modalNumber,
}: Props) {
  const customStyles = {
    content: {
      bottom: 'auto',
      width: '100%',
      maxWidth: '430px',
      margin: '0 auto',
      height: '100%',
      border: 0,
      borderRadius: 0,
      padding: 0,
      inset: '60px 0 0 0',
    },
  };

  const body = document.querySelector('body') as HTMLElement;

  useEffect(() => {
    if (isOpen) {
      body.style.overflow = 'hidden';
    } else {
      body.style.removeProperty('overflow');
    }
  }, [isOpen]);

  // 메인 모달 내용 이외에는 desc 내용이 정해지지 않았으므로
  // 해당 형태는 유지하되 현재는 메인 모달만 사용
  const getImgBy = (modalNumber: number) => {
    switch (modalNumber) {
      case 2:
        return AboutModalImg2;
      case 3:
        return AboutModalImg3;
      case 4:
        return AboutModalImg4;
      case 5:
        return AboutModalImg5;
      case 6:
        return AboutModalImg6;
      default: // === case 1
        return AboutModalImg1;
    }
  }

  const getTitleBy = (modalNumber: number) => {
    switch (modalNumber) {
      case 2:
        return "관절에 무리 없는 운동"
      case 3:
        return "튼튼한 심장"
      case 4:
        return "탄탄한 코어"
      case 5:
        return "애플힙"
      case 6:
        return "탄력있고 매끈한 다리"
      default:
        return "운동을 더욱 신나게!"
    }
  }

  const getDescBy = (modalNumber: number) => {
    switch (modalNumber) {
      case 2:
        return (
          <div className={'mb-[108px]'}>
            <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
              B52 바이크를 올바르게 핏팅하여 라이딩을 하면
            </div>
            <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
              걷기나 달리기보다 관절에 무리없이 운동할 수 있습니다.
            </div>
          </div>
        )
      case 3:
        return (
          <div className={'mb-[108px]'}>
            <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
              바이크는 심장 근육을 단련할 수 있어 심장 및 폐 건강에
            </div>
            <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
              도움이 되고 혈액 순환 및 혈압 조절력의 향상으로
            </div>
            <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
              고혈압과 같은 만성질환을 예방하는데 좋습니다.
            </div>
          </div>
        )
      case 4:
        return (
          <div className={'mb-[108px]'}>
            <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
              라이딩 자세만으로도 상체에 많은 힘이 들어가며
            </div>
            <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
              허벅지 근육을 쓰면서 연결된 코어 근육도 함께
            </div>
            <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
              탄탄해지는 효과를 보실 수 있습니다.
            </div>
          </div>
        )
      case 5:
        return (
          <div className={'mb-[108px]'}>
            <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
              페달을 밟는 동작이 엉덩이 근육을 자극하여 엉덩이를
            </div>
            <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
              탄력 있게 만드는데 도움을 줍니다. 특히, 오르막길이나
            </div>
            <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
              높은 저항으로 운동하면 효과가 더 커집니다.
            </div>

          </div>
        )
      case 6:
        return (
          <div className={'mb-[108px]'}>
            <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
              다리 근육을 고르게 자극하면서 체지방을 줄여주는
            </div>
            <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
              유산소 운동이라 근육이 과하게 커지기보다
            </div>
            <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
              탄력 있고 슬림한 다리 라인을 만들어 줍니다.
            </div>
          </div>
        )

      default:
        return (
          <>
            <div className={'mb-[18px]'}>
              <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
                B52의 운동은 감각적인 음악과
              </div>
              <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
                몰입감 있는 영상을 기반으로
              </div>
              <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
                당신의 몸과 마음, 영혼에 즐거움과
              </div>
              <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
                활력을 제공할 수 있도록 설계되었습니다.
              </div>
            </div>
            <div className={'mb-[103px]'}>
              <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
                콘텐츠에 맞춰 실시간 제어되는
              </div>
              <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
                바이크와 함께 빠르게 질주하고
              </div>
              <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
                오르막을 오르는 인터벌 운동을 통해
              </div>
              <div className={'pretendard-medium text-[#a2a2a2] text-[18px]'}>
                짧지만 강력한 운동 효과와 즐거움을 선사합니다.
              </div>
            </div>
          </>
        )
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      overlayClassName={'aboutModalOverlay'}
    >
      <div className="bg-black relative flex justify-center">
        <img
          src={XWhiteImg}
          onClick={closeModal}
          className={'absolute top-[32px] right-[25px]'}
        />
        <img src={getImgBy(modalNumber)} className={'w-full'} />
        <div className={'absolute bottom-0 text-center'}>
          <div className={'mb-[22px] pretendard-extrabold text-white text-[30px] leading-[32px]'}>
            {getTitleBy(modalNumber)}
          </div>
          {getDescBy(modalNumber)}
        </div>
      </div>
    </Modal>
  )
}
