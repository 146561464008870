import StoreLink from '../../components/small_parts/StoreLink';
import TrialImg1 from '../../assets/images/infoTrialImg1.png';
import TrialImg2 from '../../assets/images/infoTrialImg2.png';

export default function TrialContents() {
  return (
    <div className={'w-full flex flex-col items-center py-[50px]'}>
      <span className={'pretendard-extrabold'} style={styles.roundNumber}>1</span>
      <div className={'mt-[24px] pretendard-extrabold text-black text-[28px]'}>앱 다운로드</div>
      <StoreLink />
      <span className={'mt-[10px] pretendard-extrabold'} style={styles.roundNumber}>2</span>
      <div className={'mt-[24px] pretendard-extrabold text-black text-[28px]'}>회원 가입</div>
      <div className={'mt-[20px] text-center'}>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          회원가입을 차례대로 진행해주세요.
        </div>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          핸드폰 번호 인증 후 인증,
        </div>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          생년월일의 생년은 4자리로 기입,
        </div>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          쿠폰코드는 입력하지 않아도 됩니다.
        </div>
      </div>
      <img src={TrialImg1} className={`mt-[15px] w-full`} alt={'Mobile registration'} />
      <span className={'mt-[10px] pretendard-extrabold'} style={styles.roundNumber}>3</span>
      <div className={'mt-[24px] pretendard-extrabold text-black text-[28px]'}>체험 예약</div>
      <div className={'mt-[20px] text-center px-[24px]'}>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          체험 안내를 도와줄 크루 배정을 위해 당일 체험 예약은 불가합니다.
        </div>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          (D+1~7 예약 가능) 당일 체험 예약 혹은 체험 예약 당일 시간 변경을 희망하는 고객은 카카오채널 고객센터로 문의해주세요.
        </div>
      </div>
      <img src={TrialImg2} className={`mt-[15px] w-full`} alt={'Trial reservation'} />

    </div>
  )
}
const styles = {
  roundNumber: {
    color: '#ae02ef',
    fontSize: '28px',
    border: '1px solid #ae02ef',
    width: '48px',
    height: '48px',
    borderRadius: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}