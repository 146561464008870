import ArrowUpWhite from '../../assets/images/arrowUpWhite.png';

type Props = {
  img: string;
  title: string;
  desc: string;
}

export default function WelcomeLink({ img, title, desc }: Props) {
  return (
    <div className={"relative mb-[20px]"}>
      <img src={img} />
      <div className={"absolute bottom-[24px] left-[24px]"}>
        <div className={"pretendard-bold text-white text-[26px] leading-[32px]"}>
          {title}
        </div>
        <div className={"pretendard-medium text-white text-[18px]"}>
          {desc}
        </div>
        <div className={'w-[140px] mt-[20px] border-[1px] border-white rounded-full'}>
          <div className={'pl-[18px] py-[10px] flex flex-row justify-start items-center'}>
            <span className={'pretendard-medium text-white text-[18px]'}>자세히 보기</span>
            <img src={ArrowUpWhite} className={"w-[20px] h-[20px]"} />
          </div>
        </div>
      </div>
    </div>
  )
}
