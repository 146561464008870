import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import IcoUp from '../../assets/images/icoUp.png';
import IcoDown from '../../assets/images/icoDown.png';

type Props = {
  menuOpen: boolean;
  setMenuOpen: (menuOpen: boolean) => void;
}

export default function MenuLink({ menuOpen, setMenuOpen }: Props) {

  return (
    <>
      <div
        className={'pt-[20px] flex justify-center items-center cursor-pointer'}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <div className={'flex flex-row justify-center items-center select-none'}>
          <div className={'pretendard-regular text-black text-[18px]'}>카테고리 선택</div>
          <img src={menuOpen ? IcoDown : IcoUp} className={'w-[22px] h-[20px] ml-[8px]'} />
        </div>
      </div>
    </>
  )
}

const styles = {
  menuPopup: {
    width: '216px',
    height: '282px',
    margin: '0 auto',
    padding: '18px',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px 0 rgba(0, 0, 0, 0.3)',
    border: 'solid 1px #e4e4e4',
    backgroundColor: '#fff',
  }
}