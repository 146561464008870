import React from 'react';
import HomeTopImg from '../assets/images/homeTopImg.png';
import HomeImg1 from '../assets/images/homeImg1.png';
import HomeImg2 from '../assets/images/homeImg2.png';
import Img10Min from '../assets/images/img-10-min.png';
import ImgBestprice from '../assets/images/img-bestprice.png';
import Img24hours from '../assets/images/img-24-hours.png';
import Marquee from "react-fast-marquee";
import MovImg from '../assets/images/mov.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReviewItem from '../components/ReviewItem';
import StoreLink from '../components/small_parts/StoreLink';
import MoreButton from '../components/small_parts/MoreButton';
import { Link } from 'react-router-dom';

type Props = {
  modalIsOpen: boolean;
}

export default function Home({ modalIsOpen }: Props) {
  var settings = {
    customPaging: function (i: number) {
      return (
        <a>
          <div className={'w-[12px] h-[12px] rounded-full'}></div>
        </a>
      )
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  return (
    <div style={styles.container}>
      {/* section #1 */}
      <div className={'relative'}>
        <img src={HomeTopImg} className={'w-full'} alt="B52 Top" />
        <div className={'absolute bottom-0 text-white px-[20px] pb-[44px]'}>
          <span className={'pretendard-bold text-[20px]'}>It's a club, not a gym</span>
          <div className={`mt-[7px]`}>
            <div style={styles.headTextBig} className={'pretendard-extrabold'}>운동을</div>
            <div style={styles.headTextBig} className={'pretendard-extrabold'}>더욱 신나게</div>
          </div>
          <div className={'mt-[17px]'}>
            <div style={styles.headTextSmall}>화려한 조명, 감각적인 음악, 미친 비쥬얼</div>
            <div style={styles.headTextSmall}>10분만 즐기다 보면 묵었던 칼로리 폭격!</div>
            <div style={styles.headTextSmall}>오늘 쌓인 <span className={'pretendard-bold'}>스트레스 폭격!!</span></div>
            <div style={styles.headTextSmall}>퇴근 후 <span className={'pretendard-bold'}>심심함도 폭격!!!</span></div>
          </div>
          <div className={`mt-[27px] w-[120px] h-[48px] flex justify-center items-center bg-[#ae02ef] rounded-full justify-self-start cursor-pointer`}>
            <Link to='/info/trial'>
              <div className={`pretendard-bold text-white`}>체험하기</div>
            </Link>
          </div>
        </div>
      </div>
      <div className={'bg-[#ae02ef] w-full h-[46px] flex items-center'}>

        {!modalIsOpen && (
          <Marquee>
            <img src={MovImg} className={'mr-[8px]'} alt="mv1" />
            <img src={MovImg} className={'mr-[8px]'} alt="mv2" />
          </Marquee>
        )}
      </div>
      {/* section #2 */}
      <div className={'bg-[#fff] text-center py-[60px]'}>
        <div className={'pretendard-bold text-[#ae02ef] text-[20px]'}>
          입증된 운동 효과
        </div>
        <div className={'mt-[10px]'}>
          <div className={'pretendard-bold text-black text-[50px] leading-[54px]'}>
            10분이면
          </div>
          <div className={'pretendard-bold text-black text-[50px] leading-[54px]'}>
            충분한 운동
          </div>
        </div>
        <img src={HomeImg1} className={`w-full mt-[20px]`} alt="B52 Home #1" />

        <div className={'mt-[30px]'}>
          <div className={'pretendard-medium text-[20px] text-black'}>
            바쁜데 운동하려고 1시간?
          </div>
          <div className={'pretendard-medium text-[20px] text-black'}>
            이러니 운동은 항상 작심삼일!
          </div>
        </div>
        <div className={`mt-[8px] mx-[24px]`}>
          <div className={'pretendard-medium text-[20px] text-black'}>
            지루할 틈 없는 콘텐츠와 실시간 자동 제어되는 싸이클과 함께 10분 고강도 인터벌 트레이닝(HIIT)을 통해 생존에 필요한 유산소, 근력운동 끝!
          </div>
        </div>
        <Link to="/about">
          <MoreButton text={'더 알아보기'} />
        </Link>
      </div>

      {/* section #3 */}
      <div className={'mt-[14px] bg-[#fff] text-center py-[60px]'}>
        <div className={'pretendard-bold text-[#ae02ef] text-[20px]'}>
          꾸준한게 최고
        </div>
        <div className={'mt-[10px]'}>
          <div className={'pretendard-bold text-black text-[50px] leading-[54px]'}>
            매일 부담없이
          </div>
          <div className={'pretendard-bold text-black text-[50px] leading-[54px]'}>
            즐길 수 있도록
          </div>
        </div>
        <img src={HomeImg2} className={`w-full mt-[20px]`} alt="B52 Home #2" />

        <div className={'px-[24px]'}>
          <div className={`mt-[26px] flex flex-row justify-start items-center`}>
            <img src={Img10Min} alt="10Min" />
            <div className={'text-left ml-[20px]'}>
              <h1 className={`pretendard-extrabold text-black text-[20px] mb-[5px]`}>SHORT</h1>
              <div className={`pretendard-medium text-black text-[18px] leading-[24px]`}>할 일은 많고 시간은 없고</div>
              <div className={`pretendard-medium text-black text-[18px] leading-[24px]`}>하루에 딱 10분 짧고 강한 운동</div>
            </div>
          </div>
          <div className={`mt-[16px] flex flex-row justify-start items-center`}>
            <img src={ImgBestprice} alt="Best price" />
            <div className={'text-left ml-[20px]'}>
              <h1 className={`pretendard-extrabold text-black text-[20px] mb-[5px]`}>ECONOMIC</h1>
              <div className={`pretendard-medium text-black text-[18px] leading-[24px]`}>아무리 좋아도 비싸면 부담</div>
              <div className={`pretendard-medium text-black text-[18px] leading-[24px]`}>월 29,000원, 부담없는 가격</div>
            </div>
          </div>
          <div className={`mt-[16px] flex flex-row justify-start items-center`}>
            <img src={Img24hours} alt="24 hours" />
            <div className={'text-left ml-[20px]'}>
              <h1 className={`pretendard-extrabold text-black text-[20px] mb-[5px]`}>ANYTIME</h1>
              <div className={`pretendard-medium text-black text-[18px] leading-[24px]`}>언제라도 올 수 있도록</div>
              <div className={`pretendard-medium text-black text-[18px] leading-[24px]`}>연중무휴 24시간 오픈</div>
            </div>
          </div>
        </div>
      </div>

      {/* section #4 */}
      <div className={'mt-[14px] bg-[#fff] text-center py-[60px]'}>
        <div className={'pretendard-bold text-[#ae02ef] text-[20px]'}>
          이미 갓생을 시작한
        </div>
        <div className={'mt-[10px]'}>
          <div className={'pretendard-bold text-black text-[50px] leading-[54px]'}>
            B52 크루 후기
          </div>
        </div>

        <div className="sliter-container">
          <Slider {...settings}>
            <ReviewItem
              score={5}
              contents={`운동은 해야하는데.. 운동할 시간은 내기 힘들고..<br />
                혹시나 하고 체험해봤는데요.<br />
                정말 10분만에 엄청 운동되는 것 같습니다!!!!<br />
                심지어 자전거 저항이 자동으로 바뀌고 목표값?을 따라가다보니 이건 헬스장에서 타던 자전거랑은 완전히 다른 느낌으로 재미도 있어요! 😄<br />
                심지어 짧은 시간인데 타고나면 허벅지 근육 탈탈 털린 느낌도 드네요?! 👍👍<br />
                체험해보고 아 이거다! 싶어서 앱으로 구독 신청 바로 했습니다!! 정말 시간 없어도 하루 한번 10분만이라도 시간 내서 꾸준히 타면 너무 좋을 것 같아요!
              `}
              author={'arc****'}
            />
            <ReviewItem
              score={5}
              contents={`인스타에서 보니 10분만해도 칼로리 소모가 많이 된다고 하길래 체험 해 봤습니다.<br />
                클럽음악이 흘러나오면서 힙한 분위기여서 즐겁게 운동할 수 있네요.<br />
                10분 타 봤는데 페달이 자동으로 무거워졌다가 가벼워졌다가 하는게 신기하고, 이걸로 운동효과는 확실한 것 같습니다. 한 달 이용료가 29000원인데 오픈행사로 3개월은 19000원이네요.<br />
                안 할 이유가 없는 거 같습니다ㅎ 하루에 10분씩, 일주일에 1~2번만 가도 여름전에 살빼기 딱이겠네요.<br />
              `}
              author={'Daniel Park0'}
            />
            <ReviewItem
              score={5}
              contents={`진짜 재밌게 운동하고 왔습니다.<br />
                영상, 노래 너무너무 신나고<br />
                자전거가 알아서 막 바뀌는데<br />
                그거 땜에 운동효과가 대박...<br /><br />
                수업 한번에 10분인데<br />
                절대 무시하시면 안되요.<br /><br />
                한달 가격이 요즘 저녁 한끼 가격이라<br />
                부담없고 좋아요!
              `}
              author={'neo****'}
            />
          </Slider>
        </div>
      </div>

      {/* section #5 */}
      <div className={'bg-[#fff] text-center pt-[0px] pb-[60px]'}>
        <div className={'mt-[0px]'}>
          <div className={'pretendard-bold text-black text-[50px] leading-[54px]'}>
            앱 다운로드
          </div>
        </div>
        <div className={'mt-[19px]'}>
          <div className={'pretendard-medium text-[#353535] text-[20px] leading-[30px]'}>
            모바일 앱 다운받고
          </div>
          <div className={'pretendard-medium text-[#353535] text-[20px] leading-[30px]'}>
            B52의 모든 정보를 한 눈에 확인하세요!
          </div>
          <div className={'pretendard-medium text-[#353535] text-[20px] leading-[30px]'}>
            앱을 통해 운동 예약과 운동 이력을 관리해보세요.
          </div>

          <StoreLink />
        </div>
      </div>


    </div >
  );
};

const styles = {
  container: {
    // padding: '20px',
  },
  headTextBig: {
    fontSize: '68px',
    lineHeight: '68px',
    color: '#fff',
  },
  headTextSmall: {
    fontFamily: 'pretendard-medium',
    fontSize: '20px',
    color: '#fff',
  },
  sampleDiv: {
    height: '200px',
  },
  reviewBox: {
    width: '378px',
    height: '418px',
    margin: '20px 25px 40px 27px',
    padding: '28px 18px 43px 20px',
    borderRadius: '8px',
    boxShadow: '1px 3px 6px 0 rgba(0, 0, 0, 0.2)',
    border: 'solid 1px #e8e8e8',
    backgroundColor: '#fff',
  }
};
