import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import B52LogoBlack from '../assets/images/b52LogoBlack3x.png';
import BurgerImg from '../assets/images/burger.png';
import XImg from '../assets/images/x.png';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal';

type Props = {
  modalIsOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export default function Header({ modalIsOpen, openModal, closeModal }: Props) {
  const location = useLocation();

  return (
    <header style={styles.header} className={'sticky top-0 z-10 border-b-[2px] border-[#d1d1d1]'}>
      <div style={styles.logo}>
        <Link to="/">
          <img src={B52LogoBlack} className={`w-[102px] h-[20px] ml-[18px]`} alt="B52 Logo" />
        </Link>
      </div>
      <nav style={styles.nav} className={'mr-[20px]'}>
        {modalIsOpen ? (
          <img src={XImg} onClick={closeModal} />
        ) : (
          <>
            <Link
              to="/info/trial"
              style={styles.link}
              className={`bg-black py-[6px] px-[16px] rounded-full pretendard-bold`}>
              무료 체험
            </Link>
            <img src={BurgerImg} onClick={openModal} />
          </>
        )}
        {/* <Link to="/menu" style={{ color: '#000' }}>
          <img src={BurgerImg} />
        </Link> */}
      </nav>
      {/* side menu */}
      {/* <div className={'absolute top-0 z-10 w-full h-full bg-white'}>
        <h1 className={'pretendard-bold text-black'}>HelloWorld</h1>
      </div> */}
    </header>
  );
};

const styles = {
  header: {

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: '20px',
    height: '60px',
    backgroundColor: '#fff',
    color: '#fff',
  },
  logo: {
    fontSize: '1.5rem',
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
  },
  menuItemBox: {
    padding: '20px 24px 20px 24px',
    borderBottom: '1px solid #d1d1d1',
  }
};
