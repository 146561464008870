type Props = {
  imgSrc: string;
  title: string;
  desc: string;
}

export default function ContentInfo({ imgSrc, title, desc }: Props) {
  return (
    <div className={'mt-[20px] relative'}>
      <img src={imgSrc} />
      <div className={'absolute bottom-0 px-[24px] text-left mb-[38px]'}>
        <div className={'pretendard-bold text-white text-[34px] leading-[34px]'}>
          {title}
        </div>
        <div className={'pretendard-medium text-white text-[18px]'}>
          {desc}
        </div>
      </div>
    </div>
  )
}
