import React from 'react'
import InstaIconImg from '../../assets/images/ico-insta@3x.png';


export default function Footer() {
  return (
    <footer className="bg-[#ebebeb] px-[23px] py-[42px] w-full">
      <div className="text-left">
        <p className="pretendard-bold text-[18px]">(주)비오이</p>

        <address className="pretendard-medium text-[#646464] text-[16px] mt-8">
          사업자등록번호 : 742-87-03136<br />
          전화번호 : 070-7774-7998<br />
          대표이사 : 송재승<br />
          서울특별시 강남구 역삼로 118, 1층 샵 씨 (역삼동)<br />
        </address>
        <p className="pretendard-medium text-[16px] text-[#646464] mt-[4px]">© B52 Inc.</p>
      </div>
      <div className="text-left mt-[36px]">
        <a href="https://www.instagram.com/b52_official" target='_blank' rel="noreferrer">
          <img className="w-[50px] h-[50px] mt-2" src={InstaIconImg} alt="Instagram" />
        </a>
      </div>
    </footer>
  )
}
