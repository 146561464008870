import { Link } from 'react-router-dom';
import WelcomeImg1 from '../../assets/images/welcomeImg1ov.png';
import WelcomeImg2 from '../../assets/images/welcomeImg2ov.png';
import WelcomeImg3 from '../../assets/images/welcomeImg3ov.png';
import WelcomeImg4 from '../../assets/images/welcomeImg4ov.png';
import WelcomeImg5 from '../../assets/images/welcomeImg5ov.png';
import WelcomeLink from '../small_parts/WelcomeLink';

export default function WelcomeContents() {
  return (
    <>
      <div className={'bg-white'}>
        <div className={'mt-[30px] pb-[50px] text-center px-[24px]'}>
          <div className={'pretendard-medium text-[20px] text-black'}>
            B52는 당신에게 건강한 일탈을 선물합니다.
          </div>
          <div className={'pretendard-medium text-[20px] text-black'}>
            화려한 조명, 감각적인 음악, 미친 비쥬얼을 즐기며 마법의 자전거 위해서 땀을 흘리며
          </div>
          <div className={'pretendard-medium text-[20px] text-black'}>
            자신의 잠재력을 깨달아보세요.
          </div>
          <div className={'pretendard-medium text-[20px] text-black'}>
            시간이 날 때 언제든지, 딱 10분이면 됩니다.
          </div>
          <div className={'pretendard-medium text-[20px] text-black'}>
            자~ 이제 수업을 준비해볼까요?
          </div>
        </div>
      </div>

      {/* 메뉴 이동 영역 */}
      <div className={'pb-[102px]'}>
        <Link to="/info/trial">
          <WelcomeLink img={WelcomeImg1} title={"체험"} desc={"B52 칼로리 폭격기는 최초 1회 무료 탑승 방법"} />
        </Link>
        <Link to="/info/ready">
          <WelcomeLink img={WelcomeImg2} title={"준비"} desc={"칼로리 폭격기 탑승을 위한 착장과 준비물"} />
        </Link>
        <Link to="/info/arrival">
          <WelcomeLink img={WelcomeImg3} title={"매장 도착"} desc={"B52 스테이션 출입 및 탑승수속"} />
        </Link>
        <Link to="/info/setup">
          <WelcomeLink img={WelcomeImg4} title={"바이크 셋업"} desc={"완벽한 폭격을 위한 바이크 피팅"} />
        </Link>
        <Link to="/info/exp">
          <WelcomeLink img={WelcomeImg5} title={"칼로리 폭격"} desc={"기기 조작법 및 효과적인 운동을 위한 가이드"} />
        </Link>
      </div>
    </>
  )
}
