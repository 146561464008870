type Props = {
  title: string;
  priceStr: string;
}

export default function PriceBox({ title, priceStr }: Props) {
  return (
    <div style={styles.priceBox}>
      <div className={'flex justify-start items-center'}>
        <div className={'mr-[10px] w-[12px] h-[12px] rounded-full bg-[#ae02ef]'} />
        <div className={'pretendard-bold text-black text-[22px]'}>{title}</div>
      </div>
      <div className={'pretendard-bold text-black text-[22px]'}>{priceStr}</div>
    </div>
  )
}

const styles = {
  priceBox: {
    width: '100%',
    height: '78px',
    paddingLeft: '24px',
    paddingRight: '24px',
    marginBottom: '14px',
    border: '1px solid #ae02ef',
    borderRadius: '23px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '1px 3px 6px 0 rgba(0, 0, 0, 0.2)',
  },
}