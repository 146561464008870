import WelcomeTopImg from '../../assets/images/welcomeTopImg.png';
import TrialTopImg from '../../assets/images/infoTrialTopImg.png';
import ReadyTopImg from '../../assets/images/infoReadyTopImg.png';
import ArrivalTopImg from '../../assets/images/infoArrivalTopImg.png';
import SetupTopImg from '../../assets/images/infoSetupTopImg.png';
import ExpTopImg from '../../assets/images/infoExpTopImg.png';
import PaymentTopImg from '../../assets/images/infoPaymentTopImg.png';

type Props = {
  subMenu: string | undefined;
}

export default function InfoHeader({ subMenu }: Props) {
  const getTitleBy = (subMenu: string | undefined) => {
    switch (subMenu) {
      case 'trial':
        return '체험';
      case 'ready':
        return '준비';
      case 'arrival':
        return '매장 도착';
      case 'setup':
        return '바이크 셋업';
      case 'exp':
        return '칼로리 폭격';
      case 'payment':
        return '결제 및 예약';
      default:
        return 'Welcome';
    }
  }

  const getTopImgBy = (subMenu: string | undefined) => {
    switch (subMenu) {
      case 'trial':
        return TrialTopImg;
      case 'ready':
        return ReadyTopImg;
      case 'arrival':
        return ArrivalTopImg;
      case 'setup':
        return SetupTopImg;
      case 'exp':
        return ExpTopImg;
      case 'payment':
        return PaymentTopImg;
      default:
        return WelcomeTopImg;
    }
  }

  const getTopDescBy = (subMenu: string | undefined) => {
    switch (subMenu) {
      case 'trial':
        return (
          <>
            건강한 일탈을 꿈꾸는 누구나 B52 칼로리 폭격기를 <br />
            1회 무료로 탑승할 수 있습니다. <br />
            체험 수업은 사전 예약제로 운영되고, B52 모바일앱을 통해 예약 가능합니다.
          </>
        )
      case 'ready':
        return (
          <>
            칼로리 폭격을 즐기기 위한 최소한의 준비사항을 안내드립니다.
          </>
        )
      case 'arrival':
        return (<></>)
      case 'setup':
        return (<></>);
      case 'exp':
        return (<></>);
      case 'payment':
        return (<></>);
      default:
        return (<></>)
    }
  }


  return (
    <div className={'mt-[20px]'}>
      <div className={'w-full text-center'}>
        <div className={'pretendard-bold text-black text-[50px]'}>
          {getTitleBy(subMenu)}
        </div>
      </div>
      <div className="mt-[50px] relative">
        <img src={getTopImgBy(subMenu)} className={'w-full'} />
        <div
          className={'absolute bottom-0 pretendard-medium text-white text-[18px] p-[24px]'}
        >
          {getTopDescBy(subMenu)}
        </div>
      </div>
    </div>
  )
}