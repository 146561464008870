import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom';
import { API_HOST } from '../constants/API_HOST';
import useSWR from 'swr';
import { BannerType } from './Notice';
import Loading from '../components/Loading';

type BannerDataType = {
  studio_banner_id: number;
  type: string;
  value: string;
  ordering: number;
}

export default function NoticeDetail() {
  const { id } = useParams();

  const fetcher = (url: string) => fetch(API_HOST + url).then((res) => res.json());
  const { data: bannerListData, isLoading: listIsLoading, error: bannerListDataError } = useSWR<BannerType[]>(
    "/api/v1/commons/banners/", fetcher
  );

  const { data: bannerDetail, isLoading: detailIsLoading, error: bannerDetailError } = useSWR<BannerDataType[]>(
    `/api/v1/commons/banners/${id}`, fetcher
  )

  const currentBanner = useMemo(() => {
    let pageId = id && parseInt(id);
    return bannerListData?.find(v => v.studio_banner_id === pageId);
  }, [bannerListData]);

  return (
    <div>
      {currentBanner && (
        <div className={'bg-white'}>
          <img src={currentBanner.img_url} className={'bg-black'} />
          <div className={'mt-[30px] px-[24px]'}>
            <h1 className={'pretendard-bold text-[#353535] text-[22px]'}>{currentBanner.title}</h1>
          </div>
        </div>
      )}

      <div className={'bg-white px-[24px] pb-[100px]'}>
        {bannerDetail?.map(v => {
          if (v.type === 'H2') {
            return <div className={'py-[10px] pretendard-bold text-[#353535] text-[18px]'}>{v.value}</div>
          } else if (v.type === 'IMAGE') {
            return <img src={v.value} className={'w-full'} />
          } else if (v.type === 'DIVIDE_LINE') {
            return <br />
          } else {
            return v.value.split("\n").map(item => (
              <div
                className={'pt-[5px] pretendard-regular text-[#353535] text-[16px]'}
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {item}<br />
              </div>
            ))
          }

        })}
      </div>

      {(listIsLoading || detailIsLoading) && <Loading />}
    </div>
  )
}
