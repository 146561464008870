import { Link } from 'react-router-dom';
import SetupImg1 from '../../assets/images/infoSetupImg1.png';
import SetupImg2 from '../../assets/images/infoSetupImg2.png';
import SetupImg3 from '../../assets/images/infoSetupImg3.png';
import MoreButton from '../../components/small_parts/MoreButton';

export default function SetupContents() {
  return (
    <div className={'w-full text-center pt-[50px] pb-[100px]'}>
      <div className={'pretendard-extrabold text-black text-[28px]'}>바이크 로그인</div>
      <div className={'mt-[20px] text-center px-[24px]'}>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          개인의 취향에 맞춰 바이크를 고르고, 모바일앱 하단에 위치한 ‘로그인’ 버튼을 누르고 바이크 스크린 내 QR코드를 촬영하여 로그인해주세요.
        </div>
      </div>
      <img src={SetupImg1} />

      <span className={'pretendard-extrabold m-auto'} style={styles.roundText}>TIP</span>
      <div className={'mt-[14px] text-center px-[24px]'}>
        <div className={'pretendard-medium text-[#424242] text-[18px]'}>
          단단한 안장을 원한다면 1열 <br />
          부드러운 안장을 원한다면 2-3열
        </div>
        <div className={'my-[10px] w-[6px] h-[6px] rounded-full bg-[#ae02ef] m-auto'} />
        <div className={'pretendard-medium text-[#424242] text-[18px]'}>
          일반 운동화를 신었다면 스트랩이 달린 페달, <br />
          싸이클링 슈즈를 신었다면 스트랩이 없는 페달
        </div>
        <div className={'my-[10px] w-[6px] h-[6px] rounded-full bg-[#ae02ef] m-auto'} />
        <div className={'pretendard-medium text-[#424242] text-[18px]'}>
          영상에 몰입하고 싶다면 1열<br />
          빵빵한 사운드가 좋다면 2열<br />
          운동하는 내 모습을 숨기고 싶다면 3열
        </div>
      </div>

      <div className={'mt-[60px] pretendard-extrabold text-black text-[28px]'}>바이크 피팅</div>
      <div className={'mt-[20px] text-center px-[24px]'}>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          자신의 신체에 맞춰 안장 및 핸들 높낮이, 안장-핸들 거리를 셋팅해주세요. <br />
          올바른 바이크 피팅을 통해 코어를 포함한 더 많은 신체 부위에 자극을 주고 운동 효과를 높일 수 있습니다.
        </div>
      </div>
      <img src={SetupImg2} className={'mt-[30px]'} />
      <img src={SetupImg3} className={'mt-[16px]'} />
      <Link to="/notice/3">
        <MoreButton text={'바이크 피팅 보기'} />
      </Link>
    </div>
  )
}

const styles = {
  roundText: {
    color: '#ae02ef',
    fontSize: '18px',
    border: '1px solid #ae02ef',
    width: '48px',
    height: '48px',
    borderRadius: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}