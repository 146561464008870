import PriceBox from '../../components/small_parts/PriceBox';
import PaymentImg1 from '../../assets/images/paymentImg1.png';

export default function PaymentContents() {
  return (
    <>
      <div className={'w-full text-center pt-[30px] pb-[50px]'}>
        <div className={'mt-[20px] text-center px-[24px]'}>
          <div className={'pretendard-medium text-[#353535] text-[20px]'}>
            B52에서 칼로리 폭격 비행이 만족스러우셨나요? 모바일앱을 통해 이용권을 구매 후, 매일 건강한 일탈을 즐겨보세요.
          </div>
        </div>
      </div>
      <div className={'bg-[#ebebeb] w-full h-[8px]'} />
      <div className={'w-full text-center py-[50px]'}>
        <div className={'pretendard-extrabold text-black text-[28px]'}>이용권 안내</div>
        <div className={'mt-[20px] text-center px-[24px]'}>
          {/* <div className={'pretendard-medium text-[#353535] text-[20px]'}>
            스스로 운동을 지속할 강한 의지가 있다면 ‘회수권’ 상품을, 작심삼일 의지박약을 도와줄 코치가 필요하다면 ‘개월권’ 상품을 추천드립니다.
          </div>
          <div className={'mt-[30px]'}>
            <PriceBox title={'1회권'} priceStr={'3,500원'} />
            <PriceBox title={'10회권'} priceStr={'29,000원'} />
            <PriceBox title={'1개월'} priceStr={'70,000원'} />
            <PriceBox title={'3개월'} priceStr={'150,000원'} />
          </div> */}
          <div className={'pretendard-medium text-[#353535] text-[20px]'}>
            감각적인 B52 스튜디오 시설과 편리하고 다양한 앱 기능들을 마음껏 이용하세요.
          </div>
          <div className={'mt-[30px]'}>
            <PriceBox title={'월간 멤버십'} priceStr={'월 29,000원'} />
          </div>
        </div>
      </div>
      <div className={'bg-[#ebebeb] w-full h-[8px]'} />
      <div className={'w-full text-center pt-[50px] pb-[80px]'}>
        <div className={'pretendard-extrabold text-black text-[28px]'}>수업 예약</div>
        <div className={'mt-[20px] text-center px-[24px]'}>
          <div className={'pretendard-medium text-[#353535] text-[20px]'}>
            모바일앱을 통해 오늘부터 1주일 뒤까지 예약이 가능합니다.<br />
            운동을 희망하는 일시를 선택 후 콘텐츠 유형, 난이도, 음악 플레이리스트를 확인하고 예약을 진행해주세요.<br />
            기간제 상품의 경우 하루에 횟수 제한없이 운동 가능합니다.
          </div>
        </div>
        <img src={PaymentImg1} className={'mt-[20px] w-full'} />
      </div>
    </>
  )
}

const styles = {
  priceBox: {
    width: '100%',
    height: '78px',
    paddingLeft: '24px',
    paddingRight: '24px',
    border: '1px solid #ae02ef',
    borderRadius: '23px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '1px 3px 6px 0 rgba(0, 0, 0, 0.2)',
  },
}