import StoreLink from '../../components/small_parts/StoreLink';
import ReadyImg1 from '../../assets/images/infoReadyImg1.png';

export default function ReadyContents() {
  return (
    <div className={'w-full text-center pt-[50px] pb-[100px]'}>
      <div className={'pretendard-extrabold text-black text-[28px]'}>드레스코드</div>
      <div className={'mt-[20px] text-center px-[24px]'}>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          청바지, 레깅스, 반바지, 티셔츠, 후드…<br />
          좋아하는 옷이라면 무엇이든 좋습니다.<br />
          당연히 움직임이 편한 옷이 더 좋겠죠?
        </div>
      </div>
      <div className={'mt-[50px] pretendard-extrabold text-black text-[28px]'}>수분 섭취</div>
      <div className={'mt-[20px] text-center px-[24px]'}>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          B52는 10분의 짧지만 강력한 운동을 제공합니다. 많은 땀을 흘린만큼 수분섭취가 이루어져야겠죠? 물이나 이온음료를 꼭 챙겨와주세요. <br />
          물론 매장에서 생수 구매 가능합니다. <br />
          (에비앙 330ml: 800원)
        </div>
      </div>
      <div className={'mt-[50px] pretendard-extrabold text-black text-[28px]'}>싸이클링 슈즈</div>
      <div className={'mt-[20px] text-center px-[24px] mb-[50px]'}>
        <div className={'pretendard-medium text-[#353535] text-[20px]'}>
          B52에는 싸이클링 슈즈 전용 바이크와 일반 운동화용 바이크가 나누어져 있습니다.<br />
          운동효과를 한층 업그레이드 하고 싶다면 싸이클링 슈즈를 구비해주세요.
        </div>
      </div>
      <img src={ReadyImg1} className={'mt-[24px] m-auto w-[281px] h-[281px]'} />
    </div>
  )
}